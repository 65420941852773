/*eslint-disable*/

import React, { useEffect, useRef, useState } from "react";

import { FormattedMessage, IntlProvider } from "react-intl";

import { DndProvider, useDrag, useDrop } from "react-dnd";

import { Link } from "react-router-dom";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

import { FiTrash2 } from "react-icons/fi";

const ItemTypes = {
    CARD: "card",
};

const DraggableMeetingList = ({
    id,
    text,
    time,
    index,

    alert,

    moveCard,
    isLastCard,
}) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => ({ id, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);

    const handleDeleteEvent = async (eventId) => {
        try {
            await client.deleteMeeting(eventId); // Call the API to delete the meeting
            setnewEvents((newevents) =>
                newevents.filter((event) => event.meeting_id !== eventId)
            ); // Update the local state
        } catch (error) {
            console.error("Failed to delete meeting:", error);
        }
    };

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div
                ref={ref}
                style={{
                    // border: "2px solid",
                    borderColor: "#f3f4f6",
                    // borderRadius: "8px",
                    // padding: "0.5rem 1rem",
                    // marginBottom: ".5rem",
                    backgroundColor: "white",
                    cursor: "move",
                    opacity,
                }}
                className="w-full"
            >
                <div className="flex flex-row p-2">
                    {/* <div className="top-0 w-2 h-50 bg-emerald-400 mr-5 rounded-t-full"></div> */}
                    <div
                        className={`w-full ${
                            isLastCard ? "" : "border-b-2 border-gray-200"
                        }`}
                    >
                        <div className="flex flex-row items-center p-2 mb-2">
                            <div className="flex flex-col w-2/3 text-left">
                                {/* {index != 0 && (
                                    <div className="w-2/3 text-gray-400 text-xs text-left mb-2">
                                        <FormattedMessage
                                            id="routing.distance"
                                            values={{ number: 1 }}
                                        />{" "}
                                        {distance} {duration}
                                    </div>
                                )} */}
                                <div className="flex flex-row">
                                    <div className="mb-2">{text} </div>
                                    {alert && (
                                        <div className="flex ml-2 flex-row items-center bg-gold rounded-full text-black text-xxxs font-bold text-center mb-2 pl-1 pr-1">
                                            <div className="">
                                                {" "}
                                                <FormattedMessage
                                                    id="routing.new_reco"
                                                    values={{ number: 1 }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="w-2/3 text-gray-400 text-sm text-left mb-2">
                                    {time.start} - {time.end}
                                </div>
                            </div>
                            <div className="flex flex-col w-1/3 left">
                                <div className="flex flex-row w-full">
                                    <div className="w-5/6"></div>
                                    <div className="w-1/6 ">
                                        <button
                                        // onClick={handleDeleteEvent(
                                        //     meeting_id
                                        // )}
                                        >
                                            <FiTrash2 />
                                        </button>
                                    </div>
                                </div>
                                <div className="left mb-4">
                                    {" "}
                                    {/* <Link to={`/client/${id}`} key={id}>
                                    <a className="transition text-xs text-royal_red border-2 border-royal_red hover:-translate-y-0 hover:scale-100 bg-white cursor-pointer hover:bg-fade px-4 py-2 mx-auto mt-3 rounded-full">
                                        Show details
                                    </a>
                                </Link> */}
                                </div>
                                <div className="left mt-5">
                                    {" "}
                                    <Link to={`/client/${id}`} key={id}>
                                        <a className="text-xs text-white border border-buttonMainBorder font-semibold bg-black cursor-pointer hover:bg-buttonMainHoverBackground hover:text-buttonMainHoverFontColor px-3 py-2 mx-auto mt-3 rounded-full">
                                            <FormattedMessage
                                                id="routing.start_visit"
                                                values={{ number: 1 }}
                                            />
                                        </a>
                                    </Link>
                                </div>
                            </div>{" "}
                        </div>
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
};

export default DraggableMeetingList;
