/*eslint-disable*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IntlProvider } from "react-intl";

import { FiChevronLeft, FiChevronRight, FiPlus } from "react-icons/fi";

import FastAPIClient from "../../client";
import fast_api_config from "../../config";

import CalendarModule from "../../components/Calendar";
import ToggleButton from "../../components/Buttons/ToggleButton";
import Loader from "../../components/Loader";
import OpenMapsButton from "../../components/Buttons/OpenMapsButton";
import DraggableMeetingList from "../../components/LayoutComponents/DraggableMeetingList";
import RoutingMap from "../../components/MapComponents/RoutingMap";
import CreateMeetingModal from "../../components/ModalComponents/CreateMeetingModal";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

import update from "immutability-helper";

import "./style.css";

// Instantiate the FastAPI client
const client = new FastAPIClient(fast_api_config);

// Messages for localization
const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const formatModalDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const Routing = () => {
    const [viewMode, setViewMode] = useState("daily"); // new state for switching views
    const location = useLocation(); // Hook to get the current URL
    const navigate = useNavigate(); // Hook to update the URL
    const queryParams = new URLSearchParams(location.search);

    const [meetings, setMeetings] = useState([]);
    const [original_meetings, setOriginalMeetings] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [filteredMeetings, setFilteredMeetings] = useState([]); // State for filtered meetings
    const now = new Date();
    const initialStartDate = formatModalDate(now); // Current date and time
    const initialEndDate = formatModalDate(
        new Date(now.getTime() + 30 * 60000)
    ); // Current date and time plus 30 minutes

    // Function to format date for display
    const formatDate = (date) => {
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    // Handle changing date by one day
    const handleNextDay = () => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate); // Create a new Date object
            newDate.setDate(newDate.getDate() + 1); // Increment the date by one day
            return newDate; // Return the new date
        });
    };

    // Handle changing date by one day
    const handlePreviousDay = () => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate); // Create a new Date object
            newDate.setDate(newDate.getDate() - 1); // Increment the date by one day
            return newDate; // Return the new date
        });
    };

    const getMeetings = async () => {
        try {
            const data = await client.getMeetings();

            setOriginalMeetings(data.data?.results);

            // Transform each meeting object to the desired structure
            const transformedMeetings =
                data.data?.results.map((meeting) => ({
                    client_id: meeting.client.client_id,
                    client_name: meeting.client.client_name,
                    meeting_id: meeting.meeting_id,
                    location: {
                        lat: meeting.client.lat,
                        lng: meeting.client.long,
                    },
                    time: {
                        start: new Date(meeting.start_date).toLocaleTimeString(
                            [],
                            { hour: "2-digit", minute: "2-digit" }
                        ),
                        end: new Date(meeting.end_date).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                        }),
                    },
                    date: meeting.start_date.split(" ")[0],
                    distance: "0 km", // Placeholder for distance
                    duration: "1 min", // Placeholder for duration
                    alert: meeting.client.alert,
                })) || [];
            setMeetings(transformedMeetings);
            filterMeetingsByDate(new Date()); // Filter meetings for today's date initially
        } catch (error) {
            console.error("Error fetching meetings:", error);
        }
    };

    const filterMeetingsByDate = (date) => {
        const selectedDateString = date.toISOString().split("T")[0]; // Get YYYY-MM-DD format
        const filtered = meetings.filter((meeting) => {
            // Extract the date portion from meeting.date
            const meetingDateString = meeting.date.split("T")[0]; // Get YYYY-MM-DD format from meeting.date

            // Compare only the date parts
            return meetingDateString === selectedDateString;
        });
        setFilteredMeetings(filtered);
    };

    // Inside useEffect or wherever appropriate, call the filter function
    useEffect(() => {
        filterMeetingsByDate(currentDate); // Call the filter whenever selectedDate changes
    }, [currentDate, meetings]);

    useEffect(() => {
        getMeetings();
    }, []);

    const handleViewToggle = () => {
        const newViewMode = viewMode === "daily" ? "weekly" : "daily";
        setViewMode(newViewMode);
        navigate(`?view=${newViewMode}`); // Update URL with the new view mode
    };

    const [refreshing, setRefreshing] = useState(true);
    const [locale, setLocale] = useState();

    useEffect(() => {
        setRefreshing(false);
        setLocale(localStorage.getItem("language"));
        // Read viewMode from URL when component mounts
        const currentView = queryParams.get("view");
        if (currentView && currentView !== viewMode) {
            setViewMode(currentView);
        }
    }, [location]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setFilteredMeetings((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            })
        );
    }, []);

    const formatEvents = (list) => {
        return list.map((item) => ({
            title: item.summary,
            meeting_id: item.meeting_id,
            start: item.start.dateTime || item.start.date,
            end: item.end.dateTime || item.end.date,
        }));
    };

    const [newEventData, setNewEventData] = useState({
        title: "",
        start: initialStartDate,
        end: initialEndDate,
    });

    const openModal = () => {
        setNewEventData({
            ...newEventData,
            start: initialStartDate,
            end: initialEndDate,
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <section className="w-full h-full flex flex-row">
                    {viewMode === "daily" ? (
                        <div className="flex md:flex-row md:items-center mt-10 md:mt-0 h-full w-full md:w-2/5 xl:w-1/3">
                            {/* Toggle Buttons to switch between views */}

                            {/* Conditionally render content based on viewMode */}

                            <div className="flex flex-col items-center w-full">
                                {/* Daily View Content */}
                                <div className="w-4/5">
                                    <div className="flex justify-end mb-20">
                                        <ToggleButton
                                            isWeekly={viewMode === "weekly"}
                                            onToggle={handleViewToggle}
                                        />
                                    </div>
                                    <div className="">
                                        <div className="flex flex-row mb-10 items-center justify-center">
                                            <button
                                                className="w-1/4 text-xs flex flex-col items-center text-lg"
                                                onClick={handlePreviousDay}
                                            >
                                                <FiChevronLeft />
                                            </button>
                                            <div className="w-1/2 text-lg">
                                                {formatDate(currentDate)}{" "}
                                                {/* Display current date */}
                                            </div>
                                            <button
                                                className="w-1/4 text-xs flex flex-col items-center text-lg"
                                                onClick={handleNextDay}
                                            >
                                                <FiChevronRight />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="overflow-y-auto h-96 custom-scrollbar">
                                        {" "}
                                        {/* Set a fixed height and allow overflow */}
                                        {filteredMeetings.length === 0 ? (
                                            <div className="text-center py-10">
                                                <h2 className="text-xl font-semibold text-gray-700 mb-2">
                                                    Oops! Il semble qu'il n'y
                                                    ait pas de réunions
                                                    aujourd'hui.
                                                </h2>
                                                <p className="text-gray-500">
                                                    Profitez de votre journée
                                                    pour planifier ou vous
                                                    reposer. 😊
                                                </p>
                                            </div>
                                        ) : (
                                            filteredMeetings.map((card, i) => (
                                                <DraggableMeetingList
                                                    key={card.id}
                                                    index={i}
                                                    id={card.client_id}
                                                    text={card.client_name}
                                                    alert={card.alert}
                                                    time={card.time}
                                                    distance={card.distance}
                                                    duration={card.duration}
                                                    meeting_id={card.meeting_id}
                                                    moveCard={moveCard}
                                                    isLastCard={
                                                        i ===
                                                        filteredMeetings.length -
                                                            1
                                                    }
                                                />
                                            ))
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="bg-black text-xxl text-white rounded-full p-2"
                                    onClick={openModal}
                                >
                                    <FiPlus />
                                </div>
                                {isModalOpen && (
                                    <CreateMeetingModal
                                        closeModal={closeModal}
                                        initialEventData={newEventData}
                                    />
                                )}
                                <OpenMapsButton meetings={original_meetings} />
                            </div>
                        </div>
                    ) : (
                        // Weekly View: Only show the calendar
                        <div className="w-4/5 mt-20 ml-40">
                            {original_meetings && (
                                <CalendarModule meetings={original_meetings} />
                            )}
                        </div>
                    )}

                    {viewMode === "daily" && meetings.length > 0 && (
                        <RoutingMap cards={filteredMeetings} />
                    )}
                </section>
            </IntlProvider>
        </DndProvider>
    );
};

export default Routing;
