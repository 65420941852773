import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import build_param from './build_params.json';
import { isPlatform } from "@ionic/react";

const frontendPath = build_param.frontendPath;
const Auth0_domain = build_param.domain;
const Auth0_clientId = build_param.client_id;

const iosOrAndroid = isPlatform('hybrid');

const callbackUri = iosOrAndroid
  ? "com.mywaydev.app://dev-myway.eu.auth0.com/capacitor/com.mywaydev.app/callback"
  : `${frontendPath}/home`;

ReactDOM.render(
    <React.StrictMode>
       <Auth0Provider

    domain={Auth0_domain}

    clientId={Auth0_clientId}
    useRefreshTokens={true}
    useRefreshTokensFallback={false}
    cacheLocation="localstorage"
    authorizationParams={{

      redirect_uri: callbackUri,
      audience: "https://www.api.myway.technology",
      scope: "openid profile read:appointments"
    }}

  >
      <App />
      </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
