import React from "react";
import { FiArrowUp, FiArrowDown } from "react-icons/fi"; // Make sure to install react-icons

const ClientStatistics = ({ statistics }) => {
    return (
        <div className="flex flex-col w-5/6 justify-center">
            {statistics.map((stat, index) => (
                <div key={index} className="  mb-2">
                    {stat.statistic_type == "comparison" &&
                        stat.display_main == true && (
                            <div className="w-full flex flex-row justify-between items-center">
                                <div className="text-left text-sm font-semibold w-2/3">
                                    {stat.data.name}
                                </div>
                                <div
                                    className={`rounded-full ${
                                        stat.data.change > 0
                                            ? "bg-gold"
                                            : "bg-rose-500"
                                    } text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                >
                                    {stat.data.value}
                                </div>
                                <div
                                    className={`flex items-center ${
                                        stat.data.change > 0
                                            ? "text-gold"
                                            : "text-rose-500"
                                    } text-xs font-semibold w-1/5 ml-2`}
                                >
                                    {stat.data.change > 0 ? (
                                        <>
                                            <span className="ml-1">
                                                {stat.data.change}%
                                            </span>
                                            <FiArrowUp />
                                        </>
                                    ) : (
                                        <>
                                            <span className="ml-1">
                                                {stat.data.change}%
                                            </span>
                                            <FiArrowDown />
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    {stat.statistic_type == "indicator" &&
                        stat.display_main == true && (
                            <div className="w-full flex flex-row justify-between items-center">
                                <div className="text-left text-sm font-semibold w-2/3">
                                    {stat.data.name}
                                </div>
                                <div
                                    className={`rounded-full ${
                                        stat.data.value == "Faible"
                                            ? "bg-gold"
                                            : "bg-rose-500"
                                    } text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                >
                                    {stat.data.value}
                                </div>
                                <div
                                    className={`flex items-center ${
                                        stat.data.change > 0
                                            ? "text-gold"
                                            : "text-rose-500"
                                    } text-xs font-semibold w-1/5 ml-2`}
                                ></div>
                            </div>
                        )}
                </div>
            ))}
        </div>
    );
};

export default ClientStatistics;
