import React, { useState } from "react";
import FastAPIClient from "../../../client";
import config from "../../../config";
const client = new FastAPIClient(config);

const ButtonComponent = ({ selectedValue, onChange }) => {
    const handleButtonClick = (value) => {
        onChange(value);
    };

    // Styles for the buttons
    const getButtonStyles = (value) => {
        if (selectedValue === null) {
            return "bg-white border border-gray-600 text-gray-600"; // Both gray if none is selected
        }
        if (selectedValue === value) {
            return value === "oui"
                ? "bg-gold text-black" // Oui selected
                : "bg-red-500 text-white"; // Non selected
        }
        return "bg-white border border-gray-600 text-gray-600"; // Default gray
    };

    return (
        <div className="flex space-x-4">
            <button
                onClick={() => handleButtonClick("oui")}
                className={`py-1 rounded-full px-6 ${getButtonStyles("oui")}`}
            >
                Oui
            </button>
            <button
                onClick={() => handleButtonClick("non")}
                className={`py-1 rounded-full px-6 ${getButtonStyles("non")}`}
            >
                Non
            </button>
        </div>
    );
};

const ReportForm = ({ reportlayout, setToggle }) => {
    const fields = reportlayout;
    const [responses, setResponses] = useState({});

    // Get current date formatted as MM/DD/YYYY
    const currentDate = new Date().toLocaleDateString("fr-FR");

    // Handle input changes and update the response state
    const handleInputChange = (fieldId, value) => {
        setResponses((prev) => ({
            ...prev,
            [fieldId]: value,
        }));
    };

    // Handle form submission
    const handleSaveVisit = async () => {
        // Prepare data payload
        const payload = {
            report_id: "99", // Replace with dynamic ID
            meeting_id: "1", // Replace with actual meeting_id
            client_id: "1", // Replace with actual client_id
            user_id: "1", // Replace with actual user_id
            responses: "testini",
            created_at: new Date().toISOString(),
        };

        setToggle(false);

        // Send data to backend
        await client.saveReport(payload);
    };

    const [selectedResponse, setSelectedResponse] = useState(null);

    return (
        <section className="w-full bg-white flex flex-col items-center mt-5">
            <div className="flex flex-col w-full md:w-4/5 items-center mt-4">
                <div className="mt-4 text-left text-xl w-full">
                    Compte Rendu
                </div>
                <div className="flex flex-row text-left w-full mt-5">
                    <div className="w-1/2">Date de la visite:</div>
                    <div className="w-1/2 text-gray-600">{currentDate}</div>
                </div>
                <div className="flex flex-row text-left w-full mt-5">
                    <div className="w-1/2">Commercial:</div>
                    <div className="w-1/2 text-gray-600">Luc</div>
                </div>
                <div className="w-full mb-5 text-left">
                    {fields.map((field) => (
                        <div
                            key={field.field_id}
                            className="flex flex-row mr-4 w-full mt-5"
                        >
                            <div className="w-1/2">{field.field_name}</div>

                            {field.input_type === "text" && (
                                <input
                                    type="text"
                                    placeholder={field.field_description}
                                    className="border border-gray-300 p-2 rounded"
                                    onChange={(e) =>
                                        handleInputChange(
                                            field.field_id,
                                            e.target.value
                                        )
                                    }
                                />
                            )}

                            {field.input_type === "yes_no" && (
                                <ButtonComponent
                                    selectedValue={selectedResponse}
                                    onChange={setSelectedResponse}
                                />
                            )}

                            {field.input_type === "list" && (
                                <div className="flex flex-col">
                                    {field.input_options
                                        .split(",")
                                        .map((option) => (
                                            <label
                                                key={option.trim()}
                                                className="flex items-center"
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="mr-2"
                                                    checked={
                                                        responses[
                                                            field.field_id
                                                        ]?.includes(
                                                            option.trim()
                                                        ) || false
                                                    }
                                                    onChange={(e) => {
                                                        const selected =
                                                            responses[
                                                                field.field_id
                                                            ] || [];
                                                        handleInputChange(
                                                            field.field_id,
                                                            e.target.checked
                                                                ? [
                                                                      ...selected,
                                                                      option.trim(),
                                                                  ]
                                                                : selected.filter(
                                                                      (opt) =>
                                                                          opt !==
                                                                          option.trim()
                                                                  )
                                                        );
                                                    }}
                                                />
                                                {option.trim()}
                                            </label>
                                        ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="flex flex-row w-full md:w-4/5 mt-5 mb-10">
                    <div className="w-3/4"></div>
                    <div className="w-1/4 flex flex-col items-end">
                        <button
                            onClick={handleSaveVisit}
                            className="w-1/2 bg-black rounded-full text-white pl pr pt-1 pb-1 font-semibold align-right"
                        >
                            Sauvgarder
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ReportForm;
