import React, { useState, useEffect } from "react";

import { faker } from "@faker-js/faker";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    ArcElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "top",
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
};

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";
import SmallStatisticsBox from "../../components/Charts/SmallStatisticsBox";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const Profile = () => {
    // if (refreshing) return !isLoggedIn ? <NotLoggedIn /> : <Loader />;

    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);

    const labels_fr = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ];
    const labels_en = [
        "January",
        "Februar",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const labels =
        locale === "en" ? labels_en : locale === "fr" ? labels_fr : [];

    // Function to generate gradient colors between white and black
    // Function to generate a gradient between two colors
    function generateGradientColors(startColor, endColor, numColors) {
        const start = {
            r: parseInt(startColor.slice(1, 3), 16),
            g: parseInt(startColor.slice(3, 5), 16),
            b: parseInt(startColor.slice(5, 7), 16),
        };
        const end = {
            r: parseInt(endColor.slice(1, 3), 16),
            g: parseInt(endColor.slice(3, 5), 16),
            b: parseInt(endColor.slice(5, 7), 16),
        };

        const colors = [];
        for (let i = 0; i < numColors; i++) {
            const ratio = i / (numColors - 1); // Calculate ratio between 0 and 1
            const r = Math.round(start.r + ratio * (end.r - start.r));
            const g = Math.round(start.g + ratio * (end.g - start.g));
            const b = Math.round(start.b + ratio * (end.b - start.b));
            colors.push(`rgb(${r}, ${g}, ${b})`);
        }
        return colors;
    }

    // Update doughnut data dynamically based on labels length
    const doughnut_data = {
        labels: ["Restaurant", "Café", "Bar", "Discotheque"], // Add labels as needed
        datasets: [
            {
                data: [300, 50, 100, 200], // Corresponding data values
                backgroundColor: generateGradientColors(
                    "#2D3F04",
                    "#C3FF36",
                    ["Restaurant", "Café", "Bar", "Discotheque"].length
                ), // Generate colors based on label count
            },
        ],
    };

    const doughnut_options = {
        plugins: {
            legend: {
                display: true,
                position: "right",
                text: "Client type",
            },
        },
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
        cutout: "70%", // Adjust this percentage to make the arcs thinner or thicker
    };

    const stacked_options = {
        plugins: {
            legend: {
                position: "right",
                display: false,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: true, // Show horizontal grid lines
                    color: "rgba(0, 0, 0, 0.1)", // Light gray color for subtle lines
                    lineWidth: 1, // Customize line thickness
                    borderDash: [5, 5], // Optional: dashed lines for a lighter look
                },
            },
        },
        maintainAspectRatio: false, // Allow control over width and height
    };

    const stacked_data2 = {
        labels,
        datasets: [
            {
                // label: beer,
                data: labels.map(() =>
                    faker.datatype.number({ min: 0, max: 1000 })
                ),
                backgroundColor: "#000000",
                borderRadius: 15,
            },
        ],
    };

    return (
        <>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className="mt-10 ml-5 md:mt-20 text-left md:ml-20 flex flex-row items-center">
                    <h1 className="mb-12 text-xl text-black mt-5">
                        <FormattedMessage
                            id="performance.my_statistics"
                            values={{ number: 1 }}
                        />
                    </h1>
                    <h1 className="mb-12 text-sm text-gray-400 mt-6 ml-5 ">
                        <FormattedMessage
                            id="performance.last_updated"
                            values={{ number: 1 }}
                        />
                        4, 2024
                    </h1>
                </div>
                <div className="flex flex-col ml-5 mr-5 md:mr-0 md:ml-0 md:flex-row pb-6 mb-20 text-black rounded-3xl">
                    <div className=" container flex flex-col bg-white rounded-3xl md:ml-10 mr-10 w-full">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-2 align-start md:ml-10">
                            <SmallStatisticsBox
                                name={"Chiffre d'affaires"}
                                value_current_year={"11 000€"}
                                change={"+10%"}
                                value_previous_year={"10 000€"}
                            />
                            <SmallStatisticsBox
                                name={"Volume"}
                                value_current_year={"16 000"}
                                change={"+10%"}
                                value_previous_year={"14 000"}
                            />
                            <SmallStatisticsBox
                                name={"Valeur du panier"}
                                value_current_year={"450€"}
                                change={"+10%"}
                                value_previous_year={"400€"}
                            />
                            <SmallStatisticsBox
                                name={"Taux de départ"}
                                value_current_year={"15%"}
                                change={"-10%"}
                                value_previous_year={"17%"}
                            />
                            <SmallStatisticsBox
                                name={"Nombre de clients"}
                                value_current_year={"49"}
                                change={"+10%"}
                                value_previous_year={"44"}
                            />
                            <SmallStatisticsBox
                                name={"Fréquence d'achat"}
                                value_current_year={"4,5 jours"}
                                change={"-5%"}
                                value_previous_year={"4"}
                            />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-2 align-start md:ml-10">
                            <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                <h3 className="text-black text-lg">
                                    <FormattedMessage
                                        id="performance.sales_by_product_type"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{
                                        minHeight: 250,
                                        maxWidth: 450,
                                    }}
                                    className="mb-5 mt-5"
                                >
                                    <Bar
                                        options={stacked_options}
                                        data={stacked_data2}
                                    />
                                </div>
                            </div>
                            <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                <h3 className="text-black text-lg ">
                                    <FormattedMessage
                                        id="performance.client_by_type"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    className="flex justify-center items-center"
                                    style={{ maxWidth: 300, margin: "0 auto" }}
                                >
                                    <Doughnut
                                        options={doughnut_options}
                                        data={doughnut_data}
                                    />
                                </div>
                            </div>
                            <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                <h3 className="text-black text-lg">
                                    Répartition par catégorie produits
                                </h3>
                                <div
                                    className="flex justify-center items-center"
                                    style={{ maxWidth: 300, margin: "0 auto" }}
                                >
                                    <Doughnut
                                        options={doughnut_options}
                                        data={doughnut_data}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        </>
    );
};

export default Profile;
