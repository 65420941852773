import React, { useState, useEffect } from "react";
import "./Switch.css";
import FastAPIClient from "../../../client";
import config from "../../../config";
import { FormattedMessage, IntlProvider } from "react-intl";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const client = new FastAPIClient(config);
const messages = { en: messages_en, pt: messages_pt, fr: messages_fr };

// Simple Switch Component
const Switch = ({ isOn, handleToggle, onColor }) => (
    <>
        <input
            checked={isOn}
            onChange={handleToggle}
            className="react-switch-checkbox"
            id="react-switch-new"
            type="checkbox"
        />
        <label
            style={{ background: isOn ? onColor : undefined }}
            className="react-switch-label"
            htmlFor="react-switch-new"
        >
            <span className="react-switch-button" />
        </label>
    </>
);

const Collapsible = ({
    item,
    text,
    read,
    getRecommendations,
    client_id,
    task_choices,
}) => {
    const [open, setOpen] = useState(false);
    const [isAccepted, setAccepted] = useState(true);
    const [selectedTask, setSelectedTask] = useState(null);
    const [locale, setLocale] = useState(
        localStorage.getItem("language") || "en"
    );

    useEffect(() => {
        setLocale(localStorage.getItem("language"));
    }, []);

    const updateNotifications = async () => {
        const payload = {
            ...item,
            read: true,
            accepted: isAccepted,
            reason: selectedTask,
            accepted_at: new Date().toISOString(),
        };

        await client.updateRecommendation(item.task_id, payload);
        await getRecommendations(client_id);
    };

    const toggleOpen = () => setOpen((prev) => !prev);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className="text-black bg-white">
                <div className="flex-col md:mr-5">
                    <div className="flex items-center">
                        <div className="flex-grow font-bold text-black">
                            <span>{text}</span>
                            {!read && (
                                <span className="inline-block ml-2 py-1 px-2 bg-gold text-black text-xs rounded-full">
                                    <FormattedMessage id="nba.new" />
                                </span>
                            )}
                        </div>
                        <button onClick={toggleOpen} className="text-lg">
                            {open ? <FiChevronUp /> : <FiChevronDown />}
                        </button>
                    </div>
                    {open && (
                        <div className="mt-5">
                            <div className="mt-5">
                                <label className="font-bold">
                                    <FormattedMessage id="nba.outcome" />
                                </label>
                            </div>
                            <div className="flex items-center">
                                <span className="mr-3">
                                    <FormattedMessage id="nba.accepted" />
                                </span>
                                <Switch
                                    isOn={!isAccepted}
                                    onColor="#FF365E"
                                    handleToggle={() =>
                                        setAccepted((prev) => !prev)
                                    }
                                />
                                <span className="ml-3">
                                    <FormattedMessage id="nba.declined" />
                                </span>
                            </div>
                            {!isAccepted &&
                                task_choices?.map((task) => (
                                    <label key={task.id} className="block">
                                        <input
                                            type="radio"
                                            value={task.value}
                                            name="task"
                                            onChange={() =>
                                                setSelectedTask(task.value)
                                            }
                                        />
                                        {task.value}
                                    </label>
                                ))}
                            <div className="flex justify-end mt-5">
                                <button
                                    onClick={updateNotifications}
                                    className="transition border bg-black hover:bg-buttonSecondaryBackground text-white font-bold px-4 py-2 rounded-full"
                                >
                                    <FormattedMessage id="nba.submit" />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </IntlProvider>
    );
};

export default Collapsible;
