import React, { useEffect, useState } from "react";

/* eslint-disable */

import Map from "../../components/MapComponents/Map";
import Lead from "../../components/LayoutComponents/Lead";
import FastAPIClient from "../../client";
import jwtDecode from "jwt-decode";
import { NotLoggedIn } from "../clients/NotLoggedIn";
import config from "../../config";
import * as moment from "moment";
import Loader from "../../components/Loader";
import PopupModalSmall from "../../components/ModalComponents/ModalSmall/PopupModalSmall";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./checkbox.css";
import MapButton from "../../components/Buttons/MapButton";
import ListButton from "../../components/Buttons/ListButton";
import { useMedia } from "react-use";
import { Geolocation } from "@capacitor/geolocation";
import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const client = new FastAPIClient(config);

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

function FilterButton({ title, options, filterFields, handleFilterChange }) {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (value) => {
        const newFilters = filterFields.zip.includes(value)
            ? filterFields.zip.filter((filterValue) => filterValue !== value)
            : [...filterFields.zip, value];
        handleFilterChange("zip", newFilters);
    };

    return (
        <div>
            <button
                onClick={togglePopup}
                className="rounded-full border border-buttonMainBorder pl-4 pr-4 pt-2 pb-2 font-semibold text-sm bg-white"
            >
                {title}
            </button>
            {isOpen && (
                <div className="popup absolute flex flex-col justify-center bg-white drop-shadow-2xl p-4 z-50">
                    {options.map((option) => (
                        <label key={option} className="mt-2">
                            {option}
                            <input
                                type="checkbox"
                                checked={filterFields.zip.includes(option)}
                                onChange={() => handleCheckboxChange(option)}
                                className="ml-5"
                            />
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
}

function haversineDistance(lat1, lon1, lat2, lon2) {
    const earthRadius = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
            Math.cos((lat2 * Math.PI) / 180) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;
    return distance.toFixed(2);
}

const Leads = () => {
    const [leads, setLeads] = useState([]);
    const [refreshing, setRefreshing] = useState(true);
    const [showMap, setShowMap] = useState(false);
    const [locale, setLocale] = useState();

    const handleMapButtonClick = () => {
        setShowMap(true);
    };

    const handleListButtonClick = () => {
        setShowMap(false);
    };

    useEffect(() => {
        fetchUserLeads();

        setLocale(localStorage.getItem("language"));
    }, []);

    const fetchUserLeads = () => {
        // Retrieve current location
        Geolocation.getCurrentPosition()
            .then((position) => {
                const { latitude, longitude } = position.coords;
                const currentLocation = { lat: latitude, lng: longitude };

                // Fetch leads
                client.getUserClients().then((data) => {
                    // Calculate distance for each lead
                    const leadsWithDistance = data?.results.map((lead) => {
                        const leadDistance = haversineDistance(
                            currentLocation.lat,
                            currentLocation.lng,
                            lead.lat,
                            lead.long
                        );
                        return { ...lead, distance: parseFloat(leadDistance) };
                    });

                    // Sort leads based on distance in ascending order
                    leadsWithDistance.sort((a, b) => a.distance - b.distance);

                    // Update state with leads including distances
                    setLeads(leadsWithDistance);
                    setRefreshing(false);
                });
            })
            .catch((error) => {
                console.error("Error getting current position:", error);
            });
    };

    const [filterFields, setFilterFields] = useState({
        zip: [],
    });

    const [filteredLeads, setFilteredLeads] = useState([]);

    const filtered = leads.filter((lead) => {
        if (filterFields.zip.length != 0) {
            return Object.keys(filterFields).reduce((acc, filter) => {
                const filterValues = filterFields[filter];
                const productValue = lead[filter];

                //This line defines what is your match
                const found = filterValues.find((fv) => fv == productValue);

                return acc && found;
            }, true);
        } else {
            return leads;
        }
    });

    const [checked, setChecked] = useState(false);

    // Function to clear all filters
    const clearAllFilters = () => {
        setFilterFields({
            zip: [],
            price_point: [],
            cuisine: [],
        });
        setFilterFlag(false);
    };

    const handleChange = (zip, event) => {
        setChecked(!checked);
        if (!filterFields.zip.includes(zip)) {
            setFilter({
                zip: filterFields.zip.concat(zip),
            });
        } else {
            let filteredArray = filterFields.zip.filter((item) => item !== zip);
            setFilter({
                zip: filteredArray,
            });
        }
    };

    const [filterFlag, setFilterFlag] = useState(false);

    useEffect(() => {
        const filteredLeads = leads.filter((lead) => {
            const hasFiltersApplied = Object.values(filterFields).some(
                (values) => values.length !== 0
            );

            if (hasFiltersApplied) {
                setFilterFlag(true);
                return Object.keys(filterFields).every((filter) => {
                    const filterValues = filterFields[filter];
                    const productValue = lead[filter];

                    return filterValues.includes(productValue);
                });
            } else {
                setFilterFlag(false);
                return leads; // If no filters are applied, include all leads
            }
        });
        // Update the state with the filtered leads
        setFilteredLeads(filteredLeads);
    }, [filterFields]);

    // Function to handle changes in filter selection
    const handleFilterChange = (field, value) => {
        setFilterFields((prevFilters) => ({
            ...prevFilters,
            [field]: value,
        }));
    };

    const [currentLocation, setCurrentLocation] = useState(null);

    useEffect(() => {
        Geolocation.getCurrentPosition()
            .then((position) => {
                const { latitude, longitude } = position.coords;
                setCurrentLocation({ lat: latitude, lng: longitude });
                // const longitude = position.coords.longitude;
            })
            .catch((error) => {
                console.error("Error getting current position:", error);
            });
    }, []); // Run once on component mount

    // Function to extract unique values for a given field from leads
    const getUniqueFieldValues = (field) => {
        const values = new Set();
        leads.forEach((lead) => {
            if (lead[field]) {
                values.add(lead[field]);
            }
        });
        return Array.from(values);
    };

    const [reminderModal, setReminderModal] = useState(false);
    const [archiveModal, setArchiveModal] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    const isMediumScreen = useMedia("(min-width: 48em)");

    const buttonStyle = `bg-buttonSecondaryBackground border-2 border-buttonSecondaryBorder cursor-pointer hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor text-buttonSecondaryFontColor px-4 py-2 mx-auto rounded-full mt-2 md:mt-0 md:ml-5 text-xs w-full`;
    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    return (
        <>
            <IntlProvider
                locale={locale}
                messages={messages[locale]}
                defaultLocale="en"
            >
                <div className="flex flex-col md:flex-row w-full h-full">
                    {(!showMap || isMediumScreen) && (
                        <div
                            className="flex float-left px-1 text-left h-full gray-50 mx-auto pr-0 pl-0 w-full md:w-1/3"
                            style={{ minWidth: 200, overflowY: "auto" }}
                        >
                            <div
                                className="mainViewport text-black rounded-b-lg pr-5 pl-5 pt-5"
                                style={{ maxHeight: 470 }}
                            >
                                <div className="text-xl font-semibold mb-5 ml-5">
                                    <FormattedMessage id="lead.around_me" />
                                </div>
                                {filtered.length &&
                                    currentLocation &&
                                    filtered.map((lead) => (
                                        <Lead
                                            showReminderModal={() =>
                                                setReminderModal(lead)
                                            }
                                            showArchiveModal={() =>
                                                setArchiveModal(lead)
                                            }
                                            lead={lead}
                                            key={lead.name}
                                            fetchLeads={fetchUserLeads}
                                            currentLocation={currentLocation}
                                        />
                                    ))}
                                {reminderModal && (
                                    <PopupModalSmall
                                        modalTitle={reminderModal?.name}
                                        onCloseBtnPress={() => {
                                            setReminderModal(false);
                                        }}
                                    >
                                        <div className="text-black pl-10 pr-10 pb-5">
                                            <div className="text-callToAction font-bold">
                                                Schedule reminder for{" "}
                                                {reminderModal?.name}
                                            </div>
                                            <div className="flex flex-row mt-3">
                                                <div className="flex-none text-black text-xs font-bold pt-2">
                                                    Select date
                                                </div>
                                                <DatePicker
                                                    className="flex text-xxs rounded-md p-1 ml-3 border border-grey"
                                                    selected={startDate}
                                                    onChange={(date) =>
                                                        setStartDate(date)
                                                    }
                                                    dateFormat="d MMMM yyyy"
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className="font-bold text-xs text-black">
                                                    Comments
                                                </label>
                                            </div>
                                            <div>
                                                <textarea
                                                    rows="3"
                                                    cols="32"
                                                    className=" border-2 text-black text-xs"
                                                ></textarea>
                                            </div>
                                            <div className="pl-40 mt-2">
                                                <a
                                                    className="bg-callToAction text-white rounded-md p-2 text-xs font-bold cursor-pointer"
                                                    onClick={() => {
                                                        setReminderModal(false);
                                                        updateNotification(
                                                            "later"
                                                        );
                                                    }}
                                                >
                                                    Save
                                                </a>
                                            </div>
                                        </div>
                                    </PopupModalSmall>
                                )}
                                {archiveModal && (
                                    <PopupModalSmall
                                        modalTitle={archiveModal?.name}
                                        onCloseBtnPress={() => {
                                            // updateNotifications()
                                            setArchiveModal(false);
                                        }}
                                    >
                                        <div className="text-black pl-10 pr-10 pb-5">
                                            <div className="text-callToAction font-bold">
                                                Archive {archiveModal?.name}
                                            </div>
                                            <div className="flex-none text-black text-xs pt-2">
                                                Archived clients can be
                                                reactivate again!
                                            </div>

                                            <div className="text-xs mt-3">
                                                <input
                                                    type="radio"
                                                    value="MALE"
                                                    name="gender"
                                                />{" "}
                                                Served by distributor
                                            </div>
                                            <div className="text-xs">
                                                <input
                                                    type="radio"
                                                    value="MALE"
                                                    name="gender"
                                                />{" "}
                                                Wrong assortment
                                            </div>
                                            <div className="text-xs">
                                                <input
                                                    type="radio"
                                                    value="MALE"
                                                    name="gender"
                                                />{" "}
                                                No matching price point
                                            </div>
                                            <div className="text-xs">
                                                <input
                                                    type="radio"
                                                    value="MALE"
                                                    name="gender"
                                                />{" "}
                                                Doesnt work with groups
                                            </div>
                                            <div className="text-xs">
                                                <input
                                                    type="radio"
                                                    value="MALE"
                                                    name="gender"
                                                />{" "}
                                                Working with competitor
                                            </div>
                                            <div className="text-xs">
                                                <input
                                                    type="radio"
                                                    value="MALE"
                                                    name="gender"
                                                />{" "}
                                                Other
                                            </div>
                                            <div className="mt-3">
                                                <textarea
                                                    rows="3"
                                                    cols="32"
                                                    className=" border-2 text-black text-xs"
                                                ></textarea>
                                            </div>
                                            <div className="pl-40 mt-2">
                                                <a
                                                    className="bg-callToAction text-white rounded-md p-2 text-xs font-bold cursor-pointer"
                                                    onClick={() => {
                                                        setArchiveModal(false);
                                                        updateNotification(
                                                            "declined"
                                                        );
                                                    }}
                                                >
                                                    Save
                                                </a>
                                            </div>
                                        </div>
                                    </PopupModalSmall>
                                )}
                            </div>
                            <MapButton onClick={handleMapButtonClick} />
                        </div>
                    )}
                    {(showMap || isMediumScreen) && (
                        <div className="flex w-full h-full md:w-2/3">
                            <div className="flex flex-row w-full mt-10 justify-center items-center md:pl-20 md:mb-5 md:ml-10 md:w-1/2 space-x-3 md:space-x-10 absolute z-50">
                                {filterFlag && (
                                    <button
                                        onClick={clearAllFilters}
                                        className="rounded-full border border-buttonMainBorder pl-4 pr-4 pt-2 pb-2 font-semibold text-sm bg-white"
                                    >
                                        <FormattedMessage
                                            id="lead.button_all"
                                            // defaultMessage="Code postal"
                                        />
                                    </button>
                                )}
                                {!filterFlag && (
                                    <button
                                        onClick={clearAllFilters}
                                        className="rounded-full border bg-buttonMainBorder pl-4 pr-4 pt-2 pb-2 font-semibold text-sm text-white"
                                    >
                                        <FormattedMessage
                                            id="lead.button_all"
                                            // defaultMessage="Code postal"
                                        />
                                    </button>
                                )}

                                <FilterButton
                                    // title={"Code postal"}
                                    title={
                                        <FormattedMessage
                                            id="lead.button_postcode"
                                            // defaultMessage="Code postal"
                                        />
                                    }
                                    options={getUniqueFieldValues("zip")}
                                    filterFields={filterFields}
                                    handleFilterChange={handleFilterChange}
                                />
                                <FilterButton
                                    title={
                                        <FormattedMessage
                                            id="lead.button_price"
                                            // defaultMessage="Code postal"
                                        />
                                    }
                                    options={getUniqueFieldValues("zip")}
                                    filterFields={filterFields}
                                    handleFilterChange={handleFilterChange}
                                />
                                <FilterButton
                                    title={
                                        <FormattedMessage
                                            id="lead.button_cuisine"
                                            // defaultMessage="Code postal"
                                        />
                                    }
                                    options={getUniqueFieldValues("zip")}
                                    filterFields={filterFields}
                                    handleFilterChange={handleFilterChange}
                                />
                            </div>
                            <div className="flex w-full h-full md:w-full md:flex sticky pb-0">
                                <div className="container float-left text-left gray-50 mx-auto">
                                    <Map leads={filtered} />
                                    <ListButton
                                        onClick={handleListButtonClick}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </IntlProvider>
        </>
    );
};

export default Leads;
