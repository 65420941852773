// React and routing
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import "./list.css";
import "@sendbird/uikit-react/dist/index.css";

// Utilities and configurations
import FastAPIClient from "../../client";
import config from "../../config";

// Internationalization
import { FormattedMessage, IntlProvider } from "react-intl";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

// Components
import Product from "../../components/LayoutComponents/Product";
import List from "../../components/ListComponents/Listelement/List";
import CustomerDetails from "../../components/LayoutComponents/CustomerDetails/CustomerDetails";
import Loader from "../../components/Loader";
import CollapseComponent from "../../components/CollapseComponents/CollapseComponent";
import BackButton from "../../components/Buttons/BackButton";
import CustomizedApp from "../../components/Sendbird/CustomizedApp";
import OrderHistory from "../../components/LayoutComponents/OrderHistory";
import VisitHistory from "../../components/LayoutComponents/VisitHistory";
import ClientOrders from "../../components/LayoutComponents/ClientOrders";
import ReportForm from "../../components/LayoutComponents/ReportForm";
import ClientStatistics from "../../components/LayoutComponents/ClientStatistics";
import CreateMeetingModal from "../../components/ModalComponents/CreateMeetingModal";
import ReminderModal from "../../components/ModalComponents/ReminderModal";

// SendBird
import { SendBirdProvider as Sendbird } from "@sendbird/uikit-react";

// Icons
import {
    FiBarChart2,
    FiBell,
    FiBookOpen,
    FiEdit,
    FiShoppingBag,
    FiShoppingCart,
    FiShuffle,
    FiUsers,
    FiFileText,
} from "react-icons/fi";

// Initialize client and messages
const client = new FastAPIClient(config);

const messages = {
    en: messages_en,
    fr: messages_fr,
};

// Custom color set for Sendbird
const colorSet = {
    "--sendbird-light-primary-500": "#000000",
    "--sendbird-light-primary-400": "#000000",
    "--sendbird-light-primary-300": "#000000",
    "--sendbird-light-primary-200": "#000000",
    "--sendbird-light-primary-100": "#000000",
};

const ClientPage = () => {
    const { clientId } = useParams();
    const [clientInfo, setClientInfo] = useState([]);
    const [product, setProductRecos] = useState([]);
    const [list, setList] = useState([]);
    const [meetinghistory, setClientMeetingHistory] = useState([]);
    const [clientorders, setClientOrders] = useState([]);
    const [clientreceipts, setClientReceipts] = useState([]);
    const [reportlayout, setReportLayout] = useState([]);
    const [clientstats, setClientStats] = useState([]);
    const targetDivRef = useRef(null);
    const [refreshing, setRefreshing] = useState(true);
    const [openmodal, setOpenModal] = useState(false);
    const [locale, setLocale] = useState();
    const [user, setUser] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [reminderModal, setReminderModal] = useState(false);

    const openModal = () => {
        setOpenModal(true); // This navigates back one step in the history
    };
    const closeModal = () => {
        setOpenModal(false); // This navigates back one step in the history
    };

    const [stringSet] = useState({
        MESSAGE_INPUT__PLACE_HOLDER: "Saisissez un message.", // You can display these texts in other languages.
        MESSAGE_INPUT__PLACE_HOLDER__DISABLED:
            "Le chat est indisponible dans ce canal.",
        MESSAGE_INPUT__PLACE_HOLDER__MUTED:
            "Le chat est indisponible parce que vous êtes sous le coup de mute.",
        CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE:
            "Nouveau(x) message(s) depuis.",
    });

    useEffect(() => {
        setLocale(localStorage.getItem("language"));
    }, []);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // This navigates back one step in the history
    };

    // useEffect to calculate the sum when productsales changes

    const updateReminderModal = (value) => {
        setReminderModal(!value);
    };

    // const navigate = useNavigate();

    const handleStartVisit = () => {
        setToggle(true);
    };

    const handleSaveVisit = () => {
        setToggle(false);
    };

    useEffect(() => {
        getUserName();
    }, []);

    useEffect(() => {
        // Scroll to the start of the target div when 'toggle' changes
        if (toggle && targetDivRef.current) {
            setTimeout(() => {
                targetDivRef.current.scrollIntoView({ behavior: "smooth" });
            }, 100); // Adjust the delay as needed a
        }
    }, [toggle, targetDivRef]);

    useEffect(() => {
        getProducts(clientId);
        getClientInfo(clientId);
        getClientMeetingHistory(1);
        getRecommendations(clientId);
        getClientReceipts(clientId);
        getClientOrders(clientId);
        getReportLayout(clientId);
        getClientStats(clientId);
        targetDivRef.current = document.getElementById("yourTargetDivId");
        setRefreshing(false);
    }, []);

    const getUserName = () => {
        client.fetchUser().then((data) => {
            setUser(data);
        });
    };

    const getProducts = (id) => {
        client.getProducts(id).then((data) => {
            setProductRecos(data?.results);
        });
    };

    const getClientInfo = (id) => {
        client.getClient(id).then((data) => {
            setClientInfo(data);
        });
    };

    const getRecommendations = (id) => {
        client.getRecommendations(id).then((data) => {
            setList(data?.results);
        });
    };

    const getClientMeetingHistory = (id) => {
        client.getMeetings(id, "2091").then((data) => {
            setClientMeetingHistory(data.data?.results);
        });
    };

    const getClientReceipts = () => {
        client.getReceipts(clientId).then((data) => {
            setClientReceipts(data?.results);
        });
    };

    const getClientOrders = () => {
        client.getProductClientOrders(clientId).then((data) => {
            setClientOrders(data?.results);
        });
    };

    const getReportLayout = () => {
        client.getReportLayout("1").then((data) => {
            setReportLayout(data?.results);
        });
    };

    const getClientStats = () => {
        client.getClientStats(clientId).then((data) => {
            setClientStats(data?.results);
        });
    };

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    return (
        <section className="flex flex-row bg-white">
            <div
                style={{
                    flex: 1,
                    overflowY: "auto",
                }}
            >
                <IntlProvider locale={locale} messages={messages[locale]}>
                    <BackButton handleGoBack={handleGoBack} />

                    <div className="flex flex-row items-center mt-5">
                        <div className="xl:ml-10 pl-4 text-left text-black bg-white text-xl font-bold uppercase text-black">
                            {clientInfo?.client_name}
                        </div>

                        <button
                            className=" ml-5 bg-white border border-buttonSecondaryBorder rounded-full text-buttonSecondaryFontColor pl-2 pr-2 text-sm hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor"
                            onClick={openModal}
                        >
                            {" "}
                            + Planifier visite
                        </button>
                        {openmodal && (
                            <CreateMeetingModal closeModal={closeModal} />
                        )}
                    </div>
                    <div className="xl:ml-10 pl-4 text-left text-gray-400 bg-white text-base font-bold uppercase text-black">
                        ID: {clientInfo?.client_id}
                    </div>
                    <div className="flex flex-col md:flex-row w-full xl:pr-10 xl:pl-10 mt-4">
                        <div className="float-left md:w-1/4 flex-col">
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.client_details"
                                        values={{ number: 1 }}
                                    />
                                }
                                icon={<FiBookOpen />}
                                maximize={false}
                                open={true}
                            >
                                {clientInfo && (
                                    <CustomerDetails customer={clientInfo} />
                                )}
                            </CollapseComponent>
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.sales"
                                        values={{ number: 1 }}
                                    />
                                }
                                icon={<FiBarChart2 />}
                                maximize={true}
                                open={true}
                                statistics={clientstats}
                            >
                                <ClientStatistics statistics={clientstats} />
                            </CollapseComponent>
                        </div>

                        <div className="flex flex-col float-left md:w-1/2">
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.nba"
                                        values={{ number: 1 }}
                                    />
                                }
                                icon={<FiUsers />}
                                maximize={false}
                                open={true}
                            >
                                <ul className="display-inline text-gray-700 mb-5 text-sm w-5/6">
                                    {list.length > 0 &&
                                        list.map((item) => (
                                            <List
                                                recommendation={item}
                                                key={item.task_id}
                                                getRecommendations={
                                                    getRecommendations
                                                }
                                                clientInfo={clientInfo}
                                                client_id={item.id}
                                            />
                                        ))}
                                    {list.length == 0 && (
                                        <h1>
                                            <FormattedMessage id="client.notasks" />
                                        </h1>
                                    )}
                                </ul>
                            </CollapseComponent>
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.products"
                                        values={{ number: 1 }}
                                    />
                                }
                                icon={<FiShoppingCart />}
                                maximize={false}
                                toggle={true}
                                open={true}
                            >
                                <ClientOrders clientorders={clientorders} />
                            </CollapseComponent>
                            {!user.storemodule && (
                                <CollapseComponent
                                    text={
                                        <FormattedMessage
                                            id="client.client_history"
                                            values={{ number: 1 }}
                                        />
                                    }
                                    icon={<FiFileText />}
                                    maximize={false}
                                    toggle={true}
                                    open={false}
                                >
                                    <OrderHistory
                                        orderhistory={clientreceipts}
                                    />
                                </CollapseComponent>
                            )}
                            {!user.storemodule && (
                                <CollapseComponent
                                    text={
                                        <FormattedMessage
                                            id="client.client_interactions"
                                            values={{ number: 1 }}
                                        />
                                    }
                                    icon={<FiUsers />}
                                    maximize={false}
                                    toggle={true}
                                    open={false}
                                >
                                    <VisitHistory
                                        visithistory={meetinghistory}
                                    />
                                </CollapseComponent>
                            )}
                        </div>

                        <div className="flex flex-col overflow-hidden touch-none float-left md:w-1/4">
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.product_reco"
                                        values={{ number: 1 }}
                                    />
                                }
                                icon={<FiShuffle />}
                                maximize={false}
                                open={true}
                            >
                                {product.length &&
                                    product.map((prod) => (
                                        <Product
                                            product={prod}
                                            key={prod.product_id}
                                        />
                                    ))}{" "}
                            </CollapseComponent>

                            <div className="rounded-md ml-3">
                                <Sendbird
                                    appId="FC256172-7B00-4B52-879E-051BADF94246"
                                    userId={"tobi"}
                                    nickname={"Tobias Holler"}
                                    colorSet={colorSet}
                                    config={{
                                        userMention: {
                                            maxMentionCount: 10,
                                            maxSuggestionCount: 15,
                                        },
                                    }}
                                    isMentionEnabled
                                    stringSet={stringSet}
                                >
                                    <CustomizedApp
                                        channelUrl={clientInfo?.sendbird_url}
                                    />
                                </Sendbird>
                            </div>
                        </div>
                    </div>
                    <div className=""></div>

                    {!toggle && (
                        <div className="absolute bottom-0 right-10 flex-col items-center">
                            <div className="flex flex-row w-full mt-5 mb-5 md:mb-5">
                                <div className="w-2/3 md:w-5/6"></div>

                                <button
                                    onClick={() => handleStartVisit()}
                                    className="bg-gold rounded-full text-lg text-black rounded-full font-semibold p-5 drop-shadow-lg "
                                >
                                    <FiEdit />
                                </button>

                                <button
                                    onClick={() =>
                                        updateReminderModal(reminderModal)
                                    }
                                    className="bg-black rounded-full text-lg text-gold font-semibold p-5 drop-shadow-lg ml-10 "
                                >
                                    <FiBell />
                                </button>
                                <button
                                    onClick={() =>
                                        window.open(
                                            "https://client.atlantique-boissons.fr/",
                                            "_blank"
                                        )
                                    }
                                    className="bg-black rounded-full text-lg text-gold font-semibold p-5 drop-shadow-lg ml-10 "
                                >
                                    <FiShoppingBag />
                                </button>
                            </div>
                        </div>
                    )}
                    <div id="yourTargetDivId"></div>
                    {toggle && (
                        <section className="w-full bg-white flex flex-col items-center border-t border-gray-100  mt-5">
                            <ReportForm
                                reportlayout={reportlayout}
                                setToggle={handleSaveVisit}
                            />
                        </section>
                    )}
                    {reminderModal && (
                        <section className="w-full bg-white flex flex-col items-center border-t border-gray-100  mt-5">
                            <ReminderModal closeModal={updateReminderModal} />
                        </section>
                    )}
                </IntlProvider>
            </div>
        </section>
    );
};

export default ClientPage;
