import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./shapedivider.css";
import { useAuth0 } from "@auth0/auth0-react";

import LoginButton from "../../components/Buttons/LoginButton";
import { isPlatform } from "@ionic/react";

const iosOrAndroid = isPlatform("hybrid");

const Login = () => {
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();

    const login_auth0 = async () => {
        loginWithRedirect();
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: `https://www.api.myway.technology`,
                scope: "read:appointments",
            },
        });
        localStorage.setItem("token_test", accessToken);
    };

    const { loginWithRedirect } = useAuth0();
    const { user, isAuthenticated } = useAuth0();

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/home");
        }
    }, [isAuthenticated]);

    return (
        <>
            <section className="bg-white ">
                <div className="flex flex-row w-full items-center justify-center">
                    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-left w-full xl:w-3/6 md:w-3/6 sm:w-full">
                        <div className="w-full max-w-xs m-auto bg-white rounded p-5 shadow-lg">
                            <header>
                                <img
                                    src={require("./MYWAY_Logo-Noir.png")}
                                    width="120"
                                    className="ml-20 mb-10 mt-10"
                                ></img>
                            </header>

                            {!iosOrAndroid && (
                                <button
                                    className="rounded-md bg-black text-white w-full font-semibold p-2"
                                    onClick={() => login_auth0()}
                                >
                                    Login
                                </button>
                            )}

                            {iosOrAndroid && <LoginButton />}

                            {isAuthenticated && (
                                <div>
                                    <img src={user.picture} alt={user.name} />
                                    <h2>{user.name}</h2>
                                    <p>{user.email}</p>
                                </div>
                            )}
                            <footer></footer>
                        </div>
                        <h1 className="text-xxs mb-5">
                            {" "}
                            © 2024 MYWAY Technology SAS, All rights reserved.
                        </h1>
                    </div>

                    <div className="custom-shape-divider-bottom-1678740708 invisible xl:w-3/6 md:w-3/6 sm:3/6 md:visible">
                        <svg
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1200 120"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                                className="shape-fill"
                            ></path>
                        </svg>
                    </div>

                    <div className="flex flex-col items-center justify-center min-h-screen bg-gold text-left w-0 invisible xl:w-3/6 md:w-3/6 sm:w-0 md:visible">
                        <h1 className="text-xxl font-bold text-black ml-10 mr-40">
                            Empower your sales reps and provide outstanding
                            customer experiences.
                        </h1>
                        <h1 className="text-black text-xl ml-10 mr-40 mt-10">
                            Grow your clients&apos; lifetime value, their
                            satisfaction, your revenue, operating margin, team
                            productivity and engagement with MYWAY.
                        </h1>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Login;
