/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
    GoogleMap,
    useJsApiLoader,
    DirectionsRenderer,
    Marker,
} from "@react-google-maps/api";

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

import { FiMapPin } from "react-icons/fi";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

const containerStyle = {
    width: "1600px",
};

const center = {
    lat: 48.8738428,
    lng: 2.3240815,
};

const exampleMapStyles = [
    {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#000000", // Darker gray for text labels
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "geometry",
        stylers: [
            {
                color: "#E0E0E0", // Light gray for country borders
            },
        ],
    },
    {
        featureType: "administrative.locality",
        elementType: "geometry",
        stylers: [
            {
                color: "#C0C0C0", // Medium gray for localities
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [
            {
                visibility: "off", // Hide points of interest
            },
        ],
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [
            {
                saturation: -100, // Remove color
            },
            {
                lightness: 30, // Make roads lighter
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified", // Simplify highway display
            },
            {
                color: "#A0A0A0", // Gray color for highways
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off", // Hide arterial road icons
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off", // Hide arterial road icons
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [
            {
                visibility: "off", // Hide transit features
            },
        ],
    },
    {
        featureType: "water",
        elementType: "all",
        stylers: [
            {
                color: "#B0B0B0", // Gray color for water features
            },
            {
                visibility: "on", // Ensure water is visible
            },
        ],
    },
];

// SVG path for FiLocation (for custom marker)

const RoutingMap = ({ cards }) => {
    const [directions, setDirections] = useState(null);
    let directionsService;

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyAM7Y8grdI5KJ6kNCTUbQrdf6ciXTZ2x8s",
    });

    useEffect(() => {
        try {
            if (cards !== null) {
                getCoordinates(cards);
            }
        } catch (error) {}
    }, [cards]);

    const getCoordinates = (cards) => {
        directionsService = new window.google.maps.DirectionsService();
        const routesCopy = Object.values(cards).map((card) => {
            return {
                location: {
                    lat: card?.location?.lat || 47.3125769,
                    lng: card?.location?.lng || 3.7536758,
                },
                stopover: true,
            };
        });

        const origin =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                      routesCopy[0].location.lat,
                      routesCopy[0].location.lng
                  )
                : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                      routesCopy[0].location.lat,
                      routesCopy[0].location.lng
                  )
                : routesCopy.pop().location;

        const waypoints = routesCopy;
        getDirection(origin, destination, waypoints);
    };

    const onMapLoad = (map) => {
        directionsService = new window.google.maps.DirectionsService();

        const routesCopy = Object.values(cards).map((card) => {
            return {
                location: {
                    lat: card?.location?.lat || 47.3125769,
                    lng: card?.location?.lng || 3.7536758,
                },
                stopover: true,
            };
        });

        const origin =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                      routesCopy[0].location.lat,
                      routesCopy[0].location.lng
                  )
                : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                      routesCopy[0].location.lat,
                      routesCopy[0].location.lng
                  )
                : routesCopy.pop().location;

        const waypoints = routesCopy;
        getDirection(origin, destination, waypoints);
    };

    const getDirection = (origin, destination, waypoints) => {
        waypoints.length >= 1
            ? directionsService.route(
                  {
                      origin: origin,
                      destination: destination,
                      travelMode: window.google.maps.TravelMode.DRIVING,
                      waypoints: waypoints,
                  },
                  (result, status) => {
                      if (status === window.google.maps.DirectionsStatus.OK) {
                          setDirections(result);
                      } else {
                      }
                  }
              )
            : directionsService.route(
                  {
                      origin: origin,
                      destination: destination,
                      travelMode: window.google.maps.TravelMode.DRIVING,
                  },
                  (result, status) => {
                      if (status === window.google.maps.DirectionsStatus.OK) {
                          setDirections(result);
                      } else {
                      }
                  }
              );
    };

    const online = true;

    const [locale, setLocale] = useState();

    useEffect(() => {
        const fetchData = async () => {
            if (!localStorage.getItem("language")) {
                console.log("No language");
                await fetchUserData();
            } else {
                setLocale(localStorage.getItem("language"));
            }
        };
        fetchData();
    });

    return isLoaded ? (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className="flex w-3/5 xl:w-2/3 hidden md:flex">
                {online && (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={12}
                        onLoad={onMapLoad}
                        options={{
                            styles: exampleMapStyles,
                            mapTypeControl: false, // Disable map type control
                            streetViewControl: false, // Disable street view control
                            zoomControl: false, // Disable zoom in and zoom out controls
                        }}
                        className="flex"
                    >
                        {cards.length > 0 && directions && (
                            <DirectionsRenderer
                                directions={directions}
                                options={{
                                    polylineOptions: {
                                        strokeColor: "#000000",
                                        strokeOpacity: 1.0,
                                        strokeWeight: 5,
                                    },
                                    markerOptions: {
                                        icon: "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==",
                                    },
                                }}
                            />
                        )}

                        {cards.map((direction, index) => (
                            <Marker
                                key={index} // A unique key for each marker
                                name={`Location ${index + 1}`}
                                position={{
                                    lat: direction.location.lat, // Assuming directions has `latitude` field
                                    lng: direction.location.lng, // Assuming directions has `longitude` field
                                }}
                                options={{
                                    icon: {
                                        url: require("./location-pointer.svg")
                                            .default, // Custom marker icon
                                        fillColor: "#C3FF36",
                                        scaledSize: new window.google.maps.Size(
                                            30,
                                            30
                                        ), // Set the size of the icon
                                    },
                                }}
                            />
                        ))}
                    </GoogleMap>
                )}
                {!online && (
                    <div className="flex flex-row items-center w-full">
                        <div className="flex flex-col items-center w-full">
                            <div>You are not online</div>
                        </div>
                    </div>
                )}
            </div>
        </IntlProvider>
    ) : (
        <></>
    );
};

export default React.memo(RoutingMap);
