import React from "react";
import SBConversation from "@sendbird/uikit-react/GroupChannel";
import { GroupChannelList } from "@sendbird/uikit-react/GroupChannelList";

import "./style.css";

const CustomizedHeader = () => {
    // const { channel } = props;
    // const channelName = channel.name;
    // const channelAvatar = useMemo(() => {
    //     if (channel.coverUrl) {
    //         return <img src={channel.coverUrl} style={{ width: "100px" }} />;
    //     }
    //     return <></>;
    // }, [channel]);
    // const channelTitle = useMemo(() => {
    //     if (channelName) {
    //         return channelName;
    //     }
    // }, [channelName]);
    return (
        <div className="customizedHeaderWrapper">
            {/* <div>{channelAvatar}</div> */}
            {/* <div>{channelTitle}</div> */}
        </div>
    );
};

export default function CustomizedApp({ channelUrl, channellist }) {
    // Set a default channel URL if none is passed in as a prop
    const defaultChannelUrl =
        "sendbird_group_channel_436013376_c60a843ff2eb56243e16fac3d9163977921893";
    const activeChannelUrl = channelUrl || defaultChannelUrl;

    return (
        <div className="customized-app">
            <div className="sendbird-app__wrap">
                {channellist && (
                    <div className="sendbird-app__channellist-wrap">
                        {/* Channel List Component can be added here */}
                        <GroupChannelList />
                    </div>
                )}
                <div className="sendbird-app__conversation-wra conversation-limited-height">
                    <SBConversation
                        channelUrl={activeChannelUrl}
                        isReactionEnabled={false}
                        renderChatHeader={<CustomizedHeader />}
                        enableMention={true}
                        // Additional props can be added here as needed
                    />
                </div>
            </div>
            {/* Settings panel can be conditionally rendered here if needed */}
        </div>
    );
}
