// React and hooks
import React, { useState, useEffect } from 'react';

// Capacitor modules
import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';

// React Router for routing
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

// Auth0
import { useAuth0 } from "@auth0/auth0-react";

// Styles
import './App.css';

// Pages
import Login from './pages/login';
import Home from './pages/home';
import ClientDashboard from './pages/clients';
import ErrorPage from './pages/error-page';
import Profile from './pages/profile';
import Leads from './pages/leads';
import Products from './pages/products';
import ManagementDashboard from './pages/management';
import Training from './pages/training/training/index';
import ClientPage from './pages/clientpage';
import Routing from './pages/routing';
import Auth0 from './pages/auth0';
import Chat from './pages/chat';

// Components
import Navigator from './components/Navigator';
import Loader from './components/Loader';
import Footer from './components/Footer';
import Header from './components/Header';



const Layout = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  // Simulating loading delay
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 100); // Adjust the timeout as needed
    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) {
    return <Loader/>
  }

  return (
    <>

      {isAuthenticated &&
      <section className="flex flex-row bg-white">
                <div className="z-10 border-r-1 border-white">
                    <Navigator />
                </div>
                <section className="w-full bg-white flex flex-col h-screen"
                 style={{
                  overflowY: "auto",
              }}>
                    <Header />
                    <div className="w-full bg-white flex flex-col h-screen" style={{
                  overflowY: "auto",
              }}>{children}</div>


                   <div className="flex-grow"></div>
                <Footer />
                </section>

            </section>
           }
      {!isAuthenticated && location.pathname === "/" &&
        <>{children}</>
      }
    </>
  );
};

const App = () => {
  const { handleRedirectCallback, logout, getAccessTokenSilently, isAuthenticated } = useAuth0();

  // Monitor token expiration
  useEffect(() => {
    const checkTokenExpiration = async () => {
      try {
        // Attempt to get a token silently
        await getAccessTokenSilently();
      } catch (error) {
        if (error.error === "login_required" || error.error === "consent_required") {
          // If token is expired, log out the user
          logout({ returnTo: window.location.origin });
        }
      }
    };

    // Set up an interval to check token expiration every minute
    const interval = setInterval(() => {
      if (isAuthenticated) {
        checkTokenExpiration();
      }
    }, 60000); // Check every 60 seconds

    return () => clearInterval(interval);
  }, [getAccessTokenSilently, logout, isAuthenticated]);

  // Existing useEffect for handleRedirectCallback...
  useEffect(() => {
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
        await handleRedirectCallback(url);
        await Browser.close();
      }
      await Browser.close();
    });
  }, [handleRedirectCallback]);

  return (
    <div className="App bg-white">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/auth0" element={<Auth0 />} />
            <Route exact path="/clients" element={<ClientDashboard />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact={true} path="*" element={<ErrorPage />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/leads" element={<Leads />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path="/dashboard" element={<ManagementDashboard />} />
            <Route exact path="/training" element={<Training />} />
            <Route exact path="/client/:clientId" element={<ClientPage/>} />
            <Route exact path="/routing" element={<Routing/>} />
            <Route path="/chat" element={<Chat/>} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
};

export default App;
