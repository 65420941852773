/* eslint-disable */
import React, { useState } from "react";
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
// import PopupModal from "../../components/Modal/PopupModal";
import "./style.css";
import { IoLocationSharp } from "react-icons/io5";

const containerStyle = {
    width: "600px",
    height: "500px",
};

const center = {
    lat: 48.8608311,
    lng: 2.3411145,
};

function PopUpMarker({ lead }) {
    const [isOpen, setOpen] = useState(false);
    const [restaurant_name, setName] = useState(false);

    /* eslint-enable */
    const handleToggleOpen = (res_name) => {
        setOpen(true);
        setName(res_name);
    };

    const handleToggleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Marker
                name={lead.name}
                position={{ lat: lead.lat, lng: lead.long }}
                options={{
                    icon: {
                        url: require("./location-pointer.svg").default,
                        fillColor: "#EB00FF",

                        scaledSize: new window.google.maps.Size(25, 25),
                    },
                }}
                onClick={() => handleToggleOpen(lead.client_name)}
            />
            {isOpen && (
                <InfoWindow
                    position={{ lat: lead.lat, lng: lead.long }}
                    onCloseClick={() => handleToggleClose()}
                >
                    <div className="ml-5 mr-5">
                        <h1 className="font-bold text-callToAction text-sm mb-2">
                            {restaurant_name}
                        </h1>
                        <h1 className="text-xs">{lead.country}</h1>
                        <h1 className="text-xs">{lead.region}</h1>
                        <h1 className="text-xs">Phone: {lead.phone}</h1>
                        <h1 className="text-xs">Relevance: Haute</h1>
                        <h1 className="text-xs">Type: {lead.client_level_4}</h1>
                        {/* <h1 className="text-xxs">Cuisines: {lead.cuisine}</h1> */}
                        <h1 className="text-xs underline">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={lead.website}
                            >
                                Site
                            </a>
                        </h1>
                        <h1 className="text-xs underline">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.google.com/maps/dir//poni+restaurant/@48.871455,2.3232533,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47e66e478f4fffff:0xf09a87b79d2d5e26!2m2!1d2.337037!2d48.8768712"
                            >
                                Directions
                            </a>
                        </h1>
                    </div>
                </InfoWindow>
            )}
        </>
    );
}

export default PopUpMarker;
