// CalendarModule.js
/*eslint-disable*/
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from "@fullcalendar/core/locales/fr";
import interactionPlugin from "@fullcalendar/interaction";
import { useNavigate } from "react-router-dom";

import { FiCalendar, FiTrash2 } from "react-icons/fi";

import ApiCalendar from "react-google-calendar-api";

import ToggleButton from "../Buttons/ToggleButton";

import CreateMeetingModal from "../ModalComponents/CreateMeetingModal";

const config = {
    clientId:
        "709516019834-qpbvvk0jnjtkdc0p42mtagu62a6v9s94.apps.googleusercontent.com",
    apiKey: "AIzaSyBQ0UciVuou0XRGUcWJxBoFK4SfIovzcV4",
    scope: "https://www.googleapis.com/auth/calendar",
    discoveryDocs: [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ],
};

import FastAPIClient from "../../client";
import fast_api_config from "../../config";
const client = new FastAPIClient(fast_api_config);

const apiCalendar = new ApiCalendar(config);

const CalendarModule = ({ meetings, height = 550 }) => {
    // const [locale, setLocale] = useState("fr");
    const [events, setEvents] = useState([]);
    const [newevents, setnewEvents] = useState([]);
    const test_events = meetings;

    const formatNewEvents = (list) => {
        return list.map((item) => ({
            title: item.client.client_name,
            meeting_id: item.meeting_id,
            start: item.start_date,
            end: item.end_date,
        }));
    };

    useEffect(() => {
        setnewEvents(formatNewEvents(meetings));
    }, []);

    const [viewMode, setViewMode] = useState("weekly"); // new state for switching views
    // const location = useLocation(); // Hook to get the current URL
    const navigate = useNavigate(); // Hook to update the URL
    // const queryParams = new URLSearchParams(location.search);
    // const initialViewMode = queryParams.get("view") || "daily"; // Get view from URL or default to "daily"

    const handleViewToggle = () => {
        const newViewMode = viewMode === "daily" ? "weekly" : "daily";
        setViewMode(newViewMode);
        navigate(`?view=${newViewMode}`); // Update URL with the new view mode
    };

    const handleEventDrop = (info) => {
        const { event, revert } = info;

        // Example validation logic
        const isValidDrop = validateDrop(event); // Implement your own validation function

        if (!isValidDrop) {
            // If the drop is not valid, revert to the original position
            revert();
        } else {
            // If valid, you might want to update the event's information or call your API
            // Here you can also update the state or send the new data to your backend
        }
    };

    // Example validation function
    const validateDrop = (event) => {
        // Add your validation logic here
        // For example, restrict moving events to specific hours
        const newStart = event.start;
        const newEnd = event.end;

        // Example condition: Prevent moving events outside working hours
        const workingHoursStart = new Date(newStart).setHours(8, 0, 0); // 08:00 AM
        const workingHoursEnd = new Date(newStart).setHours(20, 0, 0); // 08:00 PM

        return newStart >= workingHoursStart && newEnd <= workingHoursEnd;
    };

    // Modal related state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newEventData, setNewEventData] = useState({
        title: "",
        start: "",
        meeting_id: "",
        end: "",
        location: "",
    });

    const openModal = (arg) => {
        setNewEventData({
            ...newEventData,
            start: arg.date,
            end: arg.date.setHours(arg.date.getHours() + 1),
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        // setNewEventData({ title: "", start: "", end: "", location: "" }); // Reset form on close
    };

    // Function to handle adding an event when a time slot is clicked
    const handleDateClick = (arg) => {
        openModal(arg);
    };

    // Function to handle event navigation
    const handleEventClick = () => {
        // Navigate to the event detail page with the event ID
    };

    const handleDeleteEvent = async (eventId) => {
        try {
            await client.deleteMeeting(eventId); // Call the API to delete the meeting
            setnewEvents((newevents) =>
                newevents.filter((event) => event.meeting_id !== eventId)
            ); // Update the local state
        } catch (error) {
            console.error("Failed to delete meeting:", error);
        }
    };

    const renderEventContent = (eventInfo) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                className=""
            >
                <span className="text-black">{eventInfo.event.title}</span>
                <FiTrash2
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the event click
                        handleDeleteEvent(
                            eventInfo.event.extendedProps.meeting_id
                        ); // Call delete handler
                    }}
                />
            </div>
        );
    };

    return (
        <div className="flex flex-col w-full">
            <div className="flex w-full justify-end items-center mb-5">
                {" "}
                <ToggleButton
                    isWeekly={viewMode === "weekly"}
                    onToggle={handleViewToggle}
                />
            </div>

            <FullCalendar
                locale={esLocale}
                slotMinTime={"08:00:00"}
                slotMaxTime={"20:00:00"}
                plugins={[timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                events={newevents}
                editable={true}
                eventDrop={handleEventDrop}
                height={height}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                eventContent={renderEventContent}
            />
            {isModalOpen && (
                <CreateMeetingModal
                    closeModal={closeModal}
                    initialEventData={newEventData}
                />
            )}
        </div>
    );
};

export default CalendarModule;
