import React from "react";

const VisitHistory = ({ visithistory }) => {
    // Get today's date in the YYYY-MM-DD format
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to 00:00 to compare only dates

    // Filter out visits that occur in the future
    const pastVisits = visithistory
        ? visithistory.filter((visit) => {
              const visitDate = new Date(visit.start_date);
              return visitDate < today;
          })
        : [];

    return (
        <div className="pt-10 ml-10 mr-10 w-5/6">
            {pastVisits.map((visit, index) => (
                <div
                    key={visit.meeting_id || index}
                    className="list-wrapper mb-10 text-xs"
                >
                    <div className="flex flex-row justify-between items-center border-b border-gray-200 pt-2 pb-2 text-left">
                        <div className="w-1/3 text-sm font-semibold">
                            {visit.start_date.split("T")[0]}
                        </div>
                        <div className="w-1/3 text-sm font-semibold">
                            {visit.user.first_name} {visit.user.last_name}
                        </div>
                        <div className="w-1/3 text-sm font-semibold">
                            {visit.type.description}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default VisitHistory;
