import React from "react";

import { FiExternalLink } from "react-icons/fi";

const Product = ({ product }) => {
    return (
        product && (
            <>
                <div className="flex items-center mt-2 text-black">
                    <div className="flex flex-row items-center p-2 mb-1 bg-white">
                        <div className="float-left pl-2 w-1/4">
                            <img src={product?.image}></img>
                        </div>
                        <div className="float-left ml-7 text-sm w-3/4 text-left">
                            <div className="flex flex-row items-center">
                                <p className="text-sm md:text-xs xl:text-base">
                                    <b>{product?.product_name}</b>{" "}
                                </p>
                                <button
                                    className="ml-10"
                                    onClick={() =>
                                        window.open(product?.shop_url, "_blank")
                                    }
                                >
                                    <FiExternalLink />
                                </button>
                            </div>

                            <p className="mt-2 md:mt-1 xl:md-2">
                                {product?.product_desc &&
                                product.product_desc.length > 50
                                    ? `${product.product_desc.substring(
                                          0,
                                          50
                                      )}...`
                                    : product.product_desc}
                            </p>

                            {/* <p className="mt-2 md:mt-0 xl:md-2">Stock:</p> */}
                            <p className="mt-2 md:mt-0 xl:md-2 text-black">
                                18 €
                            </p>
                        </div>
                    </div>
                </div>
            </>
        )
    );
};

export default Product;
